import React, {useState} from 'react';
import SalesOneCounterparty from "../components/SalesOneCounterparty";
import axios from "../../../helpers/axios";
import {useNavigate} from "react-router";
import {SalesRegisterListRoute} from "../../routesConstants";

const CreateSalesRegister = () => {
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
  const createRegister = (data) =>{
    console.log('create')
    setLoading(true)
    axios.put("/api/salesRegister/", data)
      .then(()=>{navigate(SalesRegisterListRoute)})
      .finally(()=>setLoading(false))
  }
  return (
    <div>
      <SalesOneCounterparty cb={createRegister} data={{
        fromOneC:null,
        fromBase:null,
        description:''
      }}  saving={loading}/>
    </div>
  );
};

export default CreateSalesRegister;