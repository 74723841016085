import React, {useEffect, useState} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {Row} from "react-bootstrap";
import RailWaisOrdersItemForm from "./RailWaisOrdersItemForm";
import LoadingButton from "../../base/components/loadingButton";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {RailEditRoute} from "../../routesConstants";
import _ from 'lodash'
import ModalLivingPage from "../../../components/layout/ModalLivingPage";
import {useNavigatingAway} from "../../../helpers/useNavigatingAway";

const RailWaisForm = ({orders, onSubmit,needCheck}) => {
  const formController = useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {register,
    handleSubmit,
    getValues,
    control,
    formState:{isDirty},
    setError} = formController
  const ordersControl = useFieldArray({
    control,
    name: "orders",
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
    defaultValues: orders
  });
  useEffect(() => {
    ordersControl.replace(orders)
  }, [orders]);

  useEffect(() => {
    if(needCheck){
      let newList= []
      needCheck.forEach(i=>{
        const order = _.find(getValues().orders,{orderNumber:i.order})
        newList.push(order)
      })
      ordersControl.replace(newList)
    }
  }, [needCheck]);
  console.log('ordersControl', ordersControl.fields)
  const removeOrder = (index) => {
    if( window.confirm('Вы уверены, что хотите удалить бронь?')){
    ordersControl.remove(index)}
  }
  const addOtherTickets = (index, tickets) => {
    // ordersControl.fields[index].tickets.push(tickets)
  }
  const submitting = (data) =>{
    console.log('here', data)
    setLoading(true)
    const dataWithoutId = _.filter(data.orders, (o)=>{
      return o.id === undefined
    })
    console.log('dataWithoutId', {orders:dataWithoutId})
    onSubmit({orders:dataWithoutId}, ()=>setLoading(false))
  }
  const filteredArray = _.filter(ordersControl.fields, (o)=>{return o.id === undefined})
  const arrayToSelect = filteredArray.map((o)=>{
    return {label:`Бронь №${o.orderNumber}`,value: o.orderNumber}
  })
  return (
    <>
      <form onSubmit={handleSubmit(submitting)}>
        {ordersControl.fields.map((order, index) => {
          let error = needCheck && _.find(needCheck,{order:order.orderNumber})
          if (order.id && !order.edit) {
            return <h4 className={'mb-4'}>Бронирование уже загружено. <Link target={'_blank'}
                                                                            to={RailEditRoute + order.id}>Посмотреть
              №{order.orderNumber}</Link></h4>
          }
          return (
            <>
              {error && error.errors.map((m,i)=>(<p className={'text-danger'} key={i}>{m}</p>))}
              <RailWaisOrdersItemForm
                order={order}
                key={order.customId}
                index={index}
                prefix={`orders[${index}]`}
                formController={formController}
                arrayToSelect={arrayToSelect}
                addOtherTickets={(v)=>addOtherTickets(index,v)}
                removeOrder={() => removeOrder(index)}
              />
            </>

          );
        })}
        <LoadingButton loading={loading} title={'Сохранить все'} type={'submit'}/>
      </form>
    </>

  );
};

export default RailWaisForm;