import React, {useState} from 'react';
import numberFormat from "../../../helpers/numberFormat";
import _ from "lodash";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";

import DataTable from "react-data-table-component";
import SearchForm from "../components/searchForm";
import {Link} from "react-router-dom";
import {IncomeOneRoute} from "../../routesConstants";
import {Tab, Tabs} from "react-bootstrap";
import {Col, Row} from "reactstrap";
import {diagramColors, hoverDiagramColors} from "../components/colors";
import {Chart} from "react-chartjs-2";

const IncomeList = () => {
  const [data, setData] = useState()
  const [period, setPeriod] = useState({})
  const [tabIndex, setTabIndex] = useState("counter");

  console.log('period', period)
  const standartColumns =
    [
    {
      name: 'Крс 1с',
      selector: row => row.krs1C,
      cell: row => `${numberFormat(row.krs1C)}`,
      sortable: true,
    },
    {
      name: 'КрсКаяр 1с',
      selector: row => row.krsKayar1C,
      cell: row => `${numberFormat(row.krsKayar1C)}`,
      sortable: true,
    },
    {
      name: 'Крс Сайт',
      selector: row => row.krsSite,
      cell: row => `${numberFormat(row.krsSite)}`,
      sortable: true,
    },
    {
      id:'total',
      name: 'Итого',
      selector: row => row.total,
      cell: row => `${numberFormat(row.total)}`,
      sortable: true,
    },
  ];
  const counterpartyCol = [
    {
      name: 'Имя',
      selector: row => row.counterparty.name ,
      cell: row=> <Link to={{
        pathname:"one",
        search: `?start=${period.start}&end=${period.end}&id=${row.counterparty.id}&unComp=${period.uncompleted}`
      }}
                        target={'_blank'} >
        {row.counterparty.name}
      </Link>,
      sortable: true,
    },
    ...standartColumns
  ]
  const agentCol = [
    {
      name: 'Имя',
      selector: row => row.operator ,
      cell: row=> <><i className="fa fa-certificate" style={{color:row.color, marginRight:'0.5rem'}}/>{row.operator}</>,
      sortable: true,
    },
    ...standartColumns
  ]


  const generateTotalData = (table) =>{
    return {
      counterparty: 'Итого',
      krs1C: _.sumBy(table, 'krs1C') || 0,
      krsKayar1C: _.sumBy(table, 'krsKayar1C') || 0,
      krsSite: _.sumBy(table, 'krsSite') || 0,
      total: _.sumBy(table, 'total') || 0,
    }
  }


  return (
    <Portlet>
      <PortletHeader title={'Доход за период'} toolbar={<PortletHeaderToolbar>
        <SearchForm cb={(data, period)=>{
          data.tableCounterparty.forEach((i,index)=>{
            i.total = i.krs1C+i.krsKayar1C+i.krsSite
          })
          data.tableIssuedBy.forEach((i,index)=>{
            i.total = i.krs1C+i.krsKayar1C+i.krsSite
            i.color = diagramColors[index]
          })
          setData(data)
          setPeriod(period)
        }} url={'/api/racoon/pdfParse/incomeReport'}/>
      </PortletHeaderToolbar>}/>

      {/*<PortletBody>*/}
      {data &&
        <Tabs
          variant="underline"
          id="controlled-tab-example"
          activeKey={tabIndex}
          style={{marginLeft: '1.8rem'}}
          onSelect={key => {
            setTabIndex(key)
          }}
        >
          <Tab eventKey="counter" title="Покупатели" >
                <DataTable
                  columns={counterpartyCol}
                  data={[generateTotalData(data.tableCounterparty)]}
                  noTableHead={true}
                  conditionalRowStyles={[{
                    when:row => row.counterparty === 'Итого',
                    style:{backgroundColor:'#00800059'}
                  }]}
                />
                <hr className={'m-0'}/>
                <DataTable
                  columns={counterpartyCol}
                  fixedHeader={true}
                  defaultSortFieldId="total"
                  defaultSortAsc={false}
                  fixedHeaderScrollHeight={'70vh'}
                  data={data.tableCounterparty}
                />


          </Tab>
          <Tab eventKey="agents" title="Агенты" >
            <Row>
              <Col lg={9}>
                <DataTable
                  columns={agentCol}
                  data={[generateTotalData(data.tableIssuedBy)]}
                  noTableHead={true}
                  conditionalRowStyles={[{
                    when:row => row.counterparty === 'Итого',
                    style:{backgroundColor:'#00800059'}
                  }]}
                />
                <hr className={'m-0'}/>
                <DataTable
                  columns={agentCol}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={'73vh'}
                  data={data.tableIssuedBy}
                />
              </Col>
              <Col lg={3}>
                <div className={'d-flex justify-content-center'}>
                  <Chart type='doughnut' data={{
                    labels: data.tableIssuedBy.map(a=>(a.operator)) || [],
                    datasets: [{
                      data: data.tableIssuedBy.map(a=>(a.total)) || [],
                      label: "Всего",
                      backgroundColor: data.tableIssuedBy.map(a=>(a.color)),
                      hoverBackgroundColor: hoverDiagramColors.slice(0, data.tableIssuedBy.length-1)
                    }]}} options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}/>
                </div>

              </Col>
            </Row>

          </Tab>
        </Tabs>}
      {(data && data?.info.length >0) &&<PortletBody>
        <div className={'text-danger'}>{data.info.join('')}</div>
      </PortletBody>}
    </Portlet>
  );

};

export default IncomeList;
