import React, {useEffect, useState} from 'react';
import axios from "../../../helpers/axios";
import {Portlet, PortletBody} from "../../../components/Portlet";
import SearchSelect from "../../../components/searchSelect";
import ApiSelect from "../../base/components/forms/apiSelect";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import {Form} from "react-bootstrap";
import DateToFromPicker from "./dateToFromPicker";
import Loader from "../../../components/loader";
import BlockUi from "@availity/block-ui";
import {all} from "axios";
import {useController, useForm} from "react-hook-form";


const tempQuery = {
  serviceType:'',
  buyer:'',
  paid:'',
  supplier:'',
  deleted:false

}
const ListOptions = ({query, setQuery, all, setAll}) => {
  const [clear, setClear] = useState(false)
  const [options, setOptions]=useState({
    types:null,
    issuedBy:null,
    buyer:null,
    supplier:null
  })


  useEffect(() => {
    axios.post('/api/touristServices/typesSelect')
      .then(({data})=>{
        setOptions(o=>({...o,types: data.data}))
      })

    axios.post('/api/users/emplSearch',{operatorOneS:true})
      .then(({data})=>{
        setOptions(o=>({...o,issuedBy: data.data.map(e=>({label:e.label, value:e.value._id}))}))
      })

    axios.post('/api/counterparty/searchInput', )
      .then(({data})=>{
        setOptions(o=>({...o, buyer: data.data}))
      })
    axios.post('/api/counterparty/suppliers/getAll',{type: 'service'})
      .then(({data})=>{
        setOptions(o=>({...o, supplier: data.data}))
      })

  }, []);
  return (
    <BlockUi blocking={!options.types || !options.issuedBy || !options.buyer}><Portlet style={{minHeight : "139.78px"}}>
      <PortletBody>
        {(options.types && options.issuedBy && options.buyer) && <form>
        <div className={'d-flex gap-2 mb-3'}>
          <div style={{position: 'relative', width: 200}}>
            <label className={'absolutLabel'}>Имя</label>
            <Form.Control
              name={'search'}
              defaultValue={query.pax}
              value={query.pax || ''}
              onChange={(e) => setQuery(q => ({...q, pax: e.target.value}))}
              className={'form-control'}
              type={'text'}
              placeholder="Поиск..."
            />
          </div>
          <div style={{width: 200}}>
            <SearchSelect
              onChange={(v) => {
                setQuery(q => ({...q, serviceType: v}))
              }}
              defaultValue={query.serviceType}
              label={'Тип услуг'}
              unregister
              array={[{value: ' ', label: 'Все'}, ...options.types]}/>
          </div>
          <div style={{width: 200}}>
            <SearchSelect
              onChange={v => {
                console.log('v', v)
                setQuery(q => ({...q, buyer: v}))
              }}
              defaultValue={query.buyer}
              label={'Покупатель'}
              unregister
              array={[{value: '', label: 'Все'}, ...options.buyer]}/>
          </div>
          <DateToFromPicker label={'Дата выписки'} clear={clear} setOptions={(v)=>{
            setQuery(q=>({...q, issueDate: v}))
          }}/>
          <DateToFromPicker label={'Дата услуг'} clear={clear} setOptions={(v)=>{
            setQuery(q=>({...q, execDate: v}))
          }}/>
          <div style={{width: 100}}>
            <SearchSelect
              onChange={(v) => {
                setQuery(q => ({...q, paid: v}))
              }}
              defaultValue={query.paid}
              label={"Оплачено"}
              unregister
              array={[{value: '', label: 'Все'}, {value: false, label: 'Нет'}, {
                value: true,
                label: 'Да'
              }]}/>
          </div>
        </div >
          <div className={'d-flex gap-2 justify-content-between align-items-center'}>
            <div className={'d-flex gap-2'}>
              <div style={{width: 200}}>
                <SearchSelect
                  onChange={(v) => {
                    setQuery(q => ({...q, issuedBy: v}))
                  }}
                  defaultValue={query.issuedBy}
                  unregister
                  label={'Выписал'}
                  array={[{value: '', label: 'Все'}, ...options.issuedBy]}/>
              </div>

              <div style={{width: 200}}>
                <SearchSelect
                  onChange={(v) => {
                    setQuery(q => ({...q, issuedWithoutDocs: v}))
                  }}
                  defaultValue={query.issuedWithoutDocs}
                  unregister
                  label={"Документы"}
                  array={[{value: '', label: 'Все'}, {value: false, label: 'C документами'}, {
                    value: true,
                    label: 'Без документов'
                  }]}/>
              </div>
              <div style={{width: 200}}>
                <SearchSelect
                  onChange={v => {
                    console.log('v', v)
                    setQuery(q => ({...q, supplier: v}))
                  }}
                  defaultValue={query.supplier}
                  unregister
                  label={'Поставщик'}
                  array={[{value: '', label: 'Все'}, ...options.supplier]}/>
              </div>
              <div style={{position: 'relative', width: 200}}>
                <label className={'absolutLabel'}>Индивидуальный код</label>
                <Form.Control
                  name={'idInOneC'}
                  defaultValue={query.idInOneC}
                  value={query.idInOneC||''}
                  onChange={(e) => setQuery(q => ({...q, idInOneC: e.target.value}))}
                  className={'form-control'}
                  type={'text'}
                  placeholder="Поиск..."
                />
              </div>
              <Form.Label className={'d-flex align-items-center gap-2 m-2'}>
                <input
                  defaultChecked={query.deleted}
                  checked={!!query.deleted}
                  onChange={(e) => {
                    setQuery(q => ({...q, deleted: e.target.checked}))
                  }}
                  type={"checkbox"}
                />{" "}
                Удаленные
              </Form.Label>
              <Form.Label className={'d-flex align-items-center gap-2 m-2'}>
                <input
                  defaultChecked={all}
                  checked={all}
                  onChange={(e) => {
                    setAll(!all)
                  }}
                  type={"checkbox"}
                />{" "}
                Все
              </Form.Label>
            </div>
            <div className={'pointer'} onClick={()=> {
              setQuery(tempQuery)
              setClear(true)
              setAll(false)
              setTimeout(()=>setClear(false),1000)
            }}>
              Очистить фильтр <i className={"fa fa-times-circle text-danger"}/>
            </div>
          </div>
        </form>}
      </PortletBody>
    </Portlet>
    </BlockUi>
  );
};

export default ListOptions;
