import React, {useEffect} from "react";
import {Provider, useSelector} from "react-redux";

import {history} from "./redux/reducers/index";
import initStore from "./redux/store";
import {LayoutSplashScreen} from "./_metronic";
import {AppRoutes} from "./routes/routes";
import ThemeWrapper from "./_metronic/themeWrapper";
import {PersistGate} from "redux-persist/integration/react";
import "react-datepicker/dist/react-datepicker.css";
import "cropperjs/dist/cropper.css";
import "@availity/block-ui/src/BlockUi.css";
import {HistoryRouter as Router} from "redux-first-history/rr6";

function App() {
  const {persistor, store: providerStore} = initStore;


  return (
    <Provider store={providerStore}>
      <React.Suspense fallback={<LayoutSplashScreen/>}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <ThemeWrapper>
              <AppRoutes/>
            </ThemeWrapper>
          </Router>
        </PersistGate>
      </React.Suspense>
    </Provider>
  );
}

export default App;
