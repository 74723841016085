import { Route, Routes } from "react-router";
import React from "react";
import {
  AirBookingRoute,
  AirlineRoute,
  AirplaneRoute
} from "../routesConstants";
import AirlineRouter from "./airline/router";
import AirplaneRouter from "./airplane/router";
import AirBookingRouter from "./airBooking/router";
import ErrorHandler from "../base/components/errorHandler";

export default function() {
  return (
      <Routes>
        <Route path={"airlines/*"} element={<AirlineRouter />} />
        <Route path={"airplane/*"} element={<AirplaneRouter />} />
        <Route path={"airBooking/*"} element={<AirBookingRouter />} />
      </Routes>
  );
}
