import React, {useState} from "react";
import {connect} from "react-redux";
import {CountryCreateRoute, CountryEditRoute} from "../../../routesConstants";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../../components/Portlet";
import {Link} from "react-router-dom";
import {LoadCountryList} from "../actions";
import DataTable from "../../../../components/dataTable";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import Process from "../../../../components/process";
import PortletHeaderSearch from "../../../base/components/forms/portlerHeaderSearch";
import axios from "../../../../helpers/axios";
import sayError from "../../../../helpers/sayError";
import Button from "react-bootstrap/Button";

const CountryList = (props)=>{
  const columns = [
    {
      Header: 'Название',
      accessor: 'name',
      Cell: ({value, row}) => <Link to={CountryEditRoute + row.original._id } >{value}</Link>
    },
    {
      Header: 'ISO2',
      accessor: 'iso2',
    },
    {
      Header: 'ISO3',
      accessor: 'iso3',
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (<Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={CountryEditRoute + row.original._id}><i className={"la la-edit"}/></Link>)
    }
  ]

  const [tabIndex, setTabIndex] = useState("home");
  const [query, setQuery] = useState("");
  console.log(query)
  return <>
    <Tabs
      variant="pills"
      id="controlled-tab-example"
      activeKey={tabIndex}
      onSelect={key => setTabIndex(key)}
    >
      <Tab eventKey="home" title="Список">
        <div className={"kt-grid__item"}>
          <Portlet>
            <PortletHeader
              title={"Список стран"}
              toolbar={
                <PortletHeaderToolbar>
                  <PortletHeaderSearch value={query} cb={(v,d)=>{setQuery(v)}}/>
                  <Link to={CountryCreateRoute} className="btn btn-sm btn-brand btn-elevate btn-icon-sm">
                    <i className="la la-plus"/>
                    Добавить
                  </Link>
                </PortletHeaderToolbar>}
            />
            <PortletBody>
              <DataTable columns={columns} search={query}  url={`/api/locations/countries`}/>
            </PortletBody>
          </Portlet>
        </div>
      </Tab>

      <Tab eventKey="profile" title="Действия"  className="tab-no-background">
        <Row>
          <Col md={6}>
            <Process desc={<a href={"https://restcountries.eu/rest/v2/all"}>https://restcountries.eu/rest/v2/all</a>}
                     title={"Синхронизация стран"}
                     link={"/api/locations/countries/sync"}
            />
          </Col>
        </Row>

      </Tab>
    </Tabs>
  </>
}


export default connect(null, {LoadCountryList})(CountryList)