import {AirlineEditRoute} from "../modules/routesConstants";
import {Link} from "react-router-dom";
import React, {createContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CreateByDna from "../modules/air/airline/components/CreateByDNA";
import {ModalFooter} from "reactstrap";




export const errorTranslate = (message) => {
  if(message.includes('##')){
    let m
    const parsd = []
    const regex = /##(?<name>[A-Z]+)\.(?<nameError>[A-Z]+)\.(?<id>[a-z0-9]{24})?%(?<key>.+?)#\|/mg

    while ((m = regex.exec(message) ) !== null){
      if(m.index === regex.lastIndex){
        regex.lastIndex ++
      }
      parsd.push(m.groups)
    }
    const baseMessage = message.replace(regex,'')
    return <div>
      <p>{baseMessage}</p>
      {parsd.map((m, i)=>{
        const title = `${place[m.name]?.name || m.name} с ${m.key} ${t[m.nameError] || m.nameError}`
      return <div key={i} className={'d-flex gap-2'}>
        <div>
          {title} {place[m.name].link && m.id ? <>: <Link className={'text-white text-decoration-underline'} target={'_blank'} to={place[m.name].link + m.id}>Ссылка</Link></> : ''}
        </div>
        {(m.nameError === "NOTFOUND" && !!place[m.name]?.component) && <ErrorPopUpForm title={title} name={m.name}/>}
      </div>
    })}
    </div>
  }else{
    return message
  }
}

 const ErrorPopUpForm = ({name,title}) =>{
   const [show,setShow] = useState(false)
   const [message, setMessage] = useState('')
   const afterSubmit = () =>{
     setMessage('Форма будет автоматически закрыта через 4 секунды...')
     setTimeout(()=> {
       setShow(false)
       setMessage('')
     }, 4000)

   }
  return <>
    <div onClick={()=>setShow(true)} style={{fontWeight:'bold'}} className={'pointer text-decoration-underline'}>
      Создать
    </div>
    <Modal show={!!show} onHide={()=>setShow(false)}>
    <Modal.Header closeButton>
      {title}
    </Modal.Header>
    <Modal.Body>
      {place[name].component(afterSubmit)}

    </Modal.Body>
      {message &&
        <ModalFooter>
          {message}
        </ModalFooter>}

  </Modal>
  </>
}

const t = {
  "EXISTS": "уже существует",
  "NOTFOUND": "не найдена"
}

const place = {
  "AIRLINE": {name:"Авиакомпания", link: AirlineEditRoute, component : (cb)=>(<CreateByDna cb={cb}/>)}
}

export const noDispHeader = {
  'noDispatch': true
}
