import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Link} from "react-router-dom";
import { SalesRegisterCreateRoute, SalesRegisterEditRoute} from "../../routesConstants";
import DataTable from "../../../components/dataTable";
import axios from "../../../helpers/axios";
import SearchSelect from "../../../components/searchSelect";
import {Form} from "react-bootstrap";

const ListSalesRegister = () => {

  const [query,setQuery] = useState({
    buyer:'',
    deleted:false
  })
  const [list, setList]=useState(null)
  useEffect(() => {
    axios.post('/api/counterparty/searchInput', )
      .then(({data})=>{
        setList(data.data)
      })
      .catch(e=>{
        console.log('err',e)
        window.alert(e.response.data.message[0])
      })
  }, []);
  const columns = [
    {
      Header: 'Покупатель',
      accessor: 'counterparties',
      Cell: ({value, row}) => <Link to={SalesRegisterEditRoute + row.original.id}>{value.map((counterparty,index)=><span key={index}>{counterparty}<br/></span>)}</Link>
    },
    {
      Header: 'Период',
      accessor: 'periods',
      Cell: ({value, row}) => <span>{value.map((p,index)=><span key={index}>{p}<br/></span>)}</span>
    },
    {
      Header: 'Создан когда/кем',
      accessor: 'createdDate',
      Cell: ({value, row}) => <span>{value} {row.original.createdBy}</span>
    },
    {
      Header: 'Оплачен',
      accessor: 'isPaid',
      Cell: ({value, row}) => <div style={{marginLeft:20}}>{value ? <i className="fa fa-check-circle text-success"/> :
        <i className="fa fa-times-circle text-danger"/>} </div>
    },
    {
      Header: "Действия",
      Cell: ({value, row}) => (
        <Link className={"btn btn-sm btn-clean btn-icon btn-icon-md"} to={SalesRegisterEditRoute + row.original.id}><i className={"la la-edit"}/></Link>)
    }
  ]

  return (
        <Portlet>
          <PortletHeader title={'Список реестров продаж'} toolbar={
            <PortletHeaderToolbar>
              {list && <>
                <div style={{width: 200}}>
                  <SearchSelect
                    onChange={v => {
                      console.log('v', v)
                      setQuery(q => ({...q, buyer: v}))
                    }}
                    defaultValue={query.buyer}
                    label={'Покупатель'}
                    array={[{value: '', label: 'Все'}, ...list]}/>
                </div>
                <Form.Label className={'d-flex align-items-center gap-2 m-2'}>
                  <input
                    defaultChecked={query.deleted}
                    onChange={(e) => {
                      setQuery(q => ({...q, deleted: e.target.checked}))
                    }}
                    type={"checkbox"}
                  />{" "}
                  Удаленные
                </Form.Label>
              </>}

              <Link to={SalesRegisterCreateRoute} className="btn btn-sm btn-primary">
                <i className="la la-plus"/>
                Добавить
              </Link>

            </PortletHeaderToolbar>
          }/>
          <PortletBody>
            <DataTable columns={columns} search={query} url={`/api/salesRegister/`}/>
          </PortletBody>
        </Portlet>

  );
};

export default ListSalesRegister;
