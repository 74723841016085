import { Route, Routes } from "react-router";
import CurrencyList from "./pages/list";
import CurrencyCreatePage from "./pages/create";
import CurrencyEditPage from "./pages/edit";
import React from "react";
import {
  CurrencyListRoute,
  CurrencyCreateRoute,
  CurrencyEditRoute
} from "../routesConstants";
import ErrorHandler from "../base/components/errorHandler";

class CurrencyRouter extends React.Component {
  render() {
    return (
        <Routes>
          <Route path={"/"} exact={true} element={<CurrencyList />} />
          <Route path={"create"} element={<CurrencyCreatePage />} />
          <Route
            path={"edit/:id"}
            exact={true}
            element={<CurrencyEditPage />}
          />
        </Routes>
    );
  }
}

export default CurrencyRouter;
