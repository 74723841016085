import React, {useEffect, useState} from 'react';
import {Controller, useFieldArray} from "react-hook-form";
import SearchSelect from "../../../components/searchSelect";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import Loader from "../../../components/loader";

const Counterpartiesfilds = ({data,formController}) => {
  const [counterpaties, setCounterpaties] = useState()
  const counterpartiesControl = useFieldArray({
    control: formController.control,
    name: `counterparties`,
    rules: {required: true},
    shouldUnregister: true,
    keyName: 'customId',
    defaultValue: data.counterparties
  });

  useEffect(() => {
    axios.post('/api/counterparty/searchInput')
      .then(({data})=>{
        setCounterpaties(data.data)
      })
    counterpartiesControl.replace(data.counterparties)
  }, []);

  const addCountr = ()=>{
    counterpartiesControl.append({id:''})
    console.log('plus')
  }
  const removeCountr = (index)=>{
    counterpartiesControl.remove(index)
  }
  return (
    <div>
      <h4>Контрагенты:</h4>
      {counterpaties ? counterpartiesControl.fields.map((con, index) => {
        return <React.Fragment key={index}>
          <div className={'d-flex gap-2 align-items-center mb-3'}>
            <Controller
              control={formController.control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <SearchSelect
                  onChange={(v)=>{
                    onChange(v)
                  }}
                  defaultValue={con.id}
                  style={{minWidth:250}}
                  label={'Контрагент'}
                  array={counterpaties}/>
              )}
              name={`counterparties[${index}].id`}
            />
            <i className={'fa fa-plus pointer'} onClick={addCountr}/>
            {counterpartiesControl.fields.length>1 && <i className={'fa fa-trash pointer'} onClick={()=>removeCountr(index)}/> }
          </div>
        </React.Fragment>
      }) : <i className="fa fa-spinner fa-spin"></i>}
    </div>
  );
};

export default Counterpartiesfilds;