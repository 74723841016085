import { Route, Routes } from "react-router";
import React from "react";
import ErrorHandler from "../base/components/errorHandler";
import ReviseItemListPage from "./pages/item/reviseItemListPage";
import ReviseItemPage from "./pages/item/reviseItemPage";
import ReviseListPage from "./pages/revise/reviseListPage";
import RevisePage from "./pages/revise/revisePage";
import ReviseSortPage from "./pages/revise/reviseSortPage";
import TicketsParser from "./pages/ticketsParser";
import FastCheck from "./pages/fastCheck";

export default function() {
  return (
      <Routes>
        <Route
          path={"revise/item/get/:id"}
          exact={true}
          element={<ReviseItemPage />}
        />
        <Route
          path={"revise/item"}
          exact={true}
          element={<ReviseItemListPage />}
        />
        <Route path={"revise/get/:id"} exact={true} element={<RevisePage />} />
        <Route path={"revise/sort"} element={<ReviseSortPage />} />
        <Route path={"revise"} element={<ReviseListPage />} />
        <Route path={"ticketParser"} element={<TicketsParser />} />
        <Route path={"fast"} element={<FastCheck/>} />


        {/*<Route path={OfferRoute} element={<OfferRouter/>}/>*/}
        {/*<Route path={TripItemRoute} element={<TripItemRouter/>}/>*/}
      </Routes>
  );
}
