import React from 'react'
import {PortletBody, PortletFooter} from "../../../../components/Portlet";
import {FieldArray, Formik} from "formik";
import {Badge, Col, Form, Row} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import {airClasses} from "../../../../helpers/enums";

import * as yup from "yup";
import AirlineClassForm from './airlineClassForm';

const AirlineForm  = ({data, onSubmit, className}) =>{
  const validationSchema = yup.object().shape({
    name: yup.string().required().default(""),
    iso2: yup.string().min(2).max(2).required().default(""),
    code: yup.string().min(3).max(3).default(""),
    classes: yup.array().of(yup.object().shape({
      classLetter: yup.string().min(1).max(2).required().default(""),
      classType: yup.mixed().oneOf(airClasses)
    })).default([]),
  });
  const initialValues = data?validationSchema.cast(data): validationSchema.cast();

  const classTemplate = {
    classLetter: '',
    classType: airClasses[0]
  }
  const addClass = (helpers)=>{
    helpers.push({...classTemplate});
  }
  const deleteClass = (helpers, i)=>{
    helpers.remove(i);
  }
  console.log('init values',initialValues )
  return <div className={className}>
    <Formik initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              console.log('submited')
              setSubmitting(true);
              onSubmit(values, () => {
                setSubmitting(false);
              })
            }}
    >
      {( formik ) => {
        const {values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          submitted} = formik;
        return <form noValidate={true} onSubmit={handleSubmit}>
          <PortletBody className={'d-flex flex-column gap-2'}>
            <Form.Group as={Row} >
              <Form.Label column sm="3">Название</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className={touched.name && errors.name ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">Iso2 код </Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="iso2"
                  value={values.iso2}
                  onChange={handleChange}
                  placeholder={'Двухзначный код'}
                  className={touched.iso2 && errors.iso2 ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">Трехзначный код</Form.Label>
              <Col sm={9}>
                <Form.Control
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  className={touched.code && errors.code ? "is-invalid" : null}
                  required
                  type="text"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>

              <FieldArray
                name="classes"
                render={arrayHelpers => (
                  <>
                    <Form.Label column sm="3">Классы <Badge onClick={() => addClass(arrayHelpers)} variant="success"><i className={"la la-plus"}/> Добавить</Badge></Form.Label>
                    <Col sm={9}>
                      <Form.Group>
                        {values.classes.map((item, i) => <AirlineClassForm prefix={`classes[${i}]`} values={item} formik={formik} key={i} deleteClass={()=>deleteClass(arrayHelpers, i)}/>)}
                      </Form.Group>
                    </Col>
                  </>
                )}
              />
            </Form.Group>
          </PortletBody>
          <PortletFooter>
            <Row>
              <Col md={{ span: 9, offset: 3 }}>
                {isSubmitting}
                <LoadingButton title={"Сохранить"} disabled={isSubmitting} loading={isSubmitting}/>
              </Col>
            </Row>
          </PortletFooter>
        </form>
      }}
    </Formik>
  </div>
}
export default AirlineForm;
