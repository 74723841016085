import React from "react";
import { PortletBody, PortletFooter } from "../../../../components/Portlet";
import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import * as yup from "yup";
import FormRow from "../../../base/components/forms/formRow";
import { connect } from "react-redux";
import { LoadList } from "../../../../redux/actions/base";
import CitySelect from "../../../base/components/forms/citySelect";
import _ from "lodash";

const AirportForm = ({ data, onSubmit, className, LoadList }) => {
  const validationSchema = yup.object().shape({
    name: yup.string().default(""),
    nameEn: yup
      .string()
      .required()
      .default(""),
    iataCode: yup.string().default("").required(),
    icaoCode: yup.string().default(""),
    utsOffset: yup.number().default(0),
    lon: yup.number().default(0),
    lat: yup.number().default(0),
    city: yup
      .string()
      .required()
      .default("")
  });
  console.log("data", data);
  const initialValues = data ? data : validationSchema.cast();
  return (
    <div className={className}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          console.log(22);
          setSubmitting(true);
          onSubmit(values, () => {
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => {
          console.log(errors);
          return (
            <form noValidate={true} onSubmit={handleSubmit}>
              <PortletBody className={'d-flex flex-column gap-2 mb-2'}>
                <FormRow
                  value={values.name}
                  name={"name"}
                  title={"Название"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                />
                <FormRow
                  value={values.nameEn}
                  name={"nameEn"}
                  title={"Название на английском"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                />
                <FormRow
                  value={values.iataCode}
                  name={"iataCode"}
                  title={"Iata код"}
                  placeholder={''}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                />
                <FormRow
                  value={values.icaoCode}
                  name={"icaoCode"}
                  title={"Icao код"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                />
                <FormRow
                  value={values.utsOffset}
                  name={"utsOffset"}
                  title={"Время UTC+X"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                  type={"number"}
                />
                <FormRow
                  value={values.lon}
                  name={"lon"}
                  title={"lon"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                  type={"number"}
                />
                <FormRow
                  value={values.lat}
                  name={"lat"}
                  title={"lat"}
                  errors={errors}
                  handleChange={handleChange}
                  touched={touched}
                  type={"number"}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Город
                  </Form.Label>
                  <Col sm={10}>
                    <CitySelect
                      cb={val => setFieldValue("city", val)}
                      value={values.city}
                      errors={touched.city && _.get(errors, "city")}
                    />
                  </Col>
                </Form.Group>
              </PortletBody>
              <PortletFooter>
                <Row>
                  <Col md={{ span: 10, offset: 2 }}>
                    <LoadingButton
                      title={"Сохранить"}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    />
                  </Col>
                </Row>
              </PortletFooter>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};
export default connect(null, { LoadList })(AirportForm);
