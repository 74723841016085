import { Route, Routes } from "react-router";
import ErrorHandler from "../base/components/errorHandler";
import DocumentsListPage from "./pages/list";
import DocumentsCreatePage from "./pages/create";


export default function() {
  return (
      <Routes>
        <Route path={"list"} element={<DocumentsListPage/>} />
        <Route path={"create"} element={<DocumentsCreatePage/>} />
      </Routes>
  );
}
