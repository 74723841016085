import React, {useState} from 'react';
import {Table} from "react-bootstrap";
import {Portlet, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import SearchForm from "../components/searchForm";
import numberFormat from "../../../helpers/numberFormat";

const DetailIncomePage = () => {
  const [data,setData] = useState()
  const borderS = '1px solid rgba(100, 108, 154, 0.3)'

  const generateRow = (row) =>{
    let allSuppliers = data.uniqSuppliers.avia.concat(data.uniqSuppliers.services)
    let arr = Array.apply(null, Array(allSuppliers.length))
      row.avia.forEach((sup)=>{
        arr[allSuppliers.indexOf(sup.supplier)] = sup
      })
      row.services.forEach((sup)=>{
        arr[allSuppliers.indexOf(sup.supplier)] = sup
      })
    console.log('arr '+ row.counterparty,arr)
    const generStyle = (i) =>{
      return i===data.uniqSuppliers.avia.length -1  || i===arr.length-1?
        {borderRight:borderS,backgroundColor: "#f2f2f2", fontWeight:'bold'} :
        {borderRight:borderS}
    }
    return <>
      <tr >
        <th rowSpan={3} >{row.counterparty}</th>
        {arr.map((c,i)=> (<td key={i} style={generStyle(i)} >{numberFormat(c?.netto|| 0) }</td>))}
      </tr>
      <tr >
        {arr.map((c,i)=> (<td key={i} style={generStyle(i)} >{numberFormat(c?.krs|| 0)}</td>))}
      </tr>
      <tr style={{borderBottom:borderS}}>
        {arr.map((c,i)=> (<td key={i} style={generStyle(i)} >{numberFormat(c?.summ|| 0)}</td>))}
      </tr>
    </>
  }


  return (
    <Portlet >
      <PortletHeader title={'Подробная таблица'} toolbar={<PortletHeaderToolbar>
        <SearchForm url={"/api/racoon/pdfParse/detailedReport"} cb={(data, period)=>{
          setData(data)
        }}/>
      </PortletHeaderToolbar>}/>
      {data && <div className="tableFixHead">
        <table>
          <thead>
          <tr>
            <th></th>
            <th colSpan={data.uniqSuppliers.avia.length}>Билеты</th>
            <th colSpan={data.uniqSuppliers.services.length} style={{borderLeft:borderS}}>Тур/услуги</th>
          </tr>
          <tr>
            <th>Контрагент</th>
            {data.uniqSuppliers.avia.map((n, i) => (<th key={i}>{n}</th>))}
            {data.uniqSuppliers.services.map((n, i) => (<th key={i} style={{borderLeft: i===0 ?borderS:''}}>{n}</th>))}
          </tr>
          </thead>
          <tbody>
          {data.table.map((row, i) => (generateRow(row)))}
          </tbody>
        </table>
      </div>}
    </Portlet>
  );
};

export default DetailIncomePage;
