import React, {useState} from 'react';
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../../components/Portlet";
import {useForm} from "react-hook-form";
import FormHookInput from "../../airBooking/components/formHookInput";
import LoadingButton from "../../../base/components/loadingButton";
import axios from "../../../../helpers/axios";
import sayError from "../../../../helpers/sayError";

const CreateAirlineByIsoCode = () => {
  const [loading, setLoading] = useState(false)
  const [desc, setDesc] = useState('')
  const {register,
    handleSubmit,
    formState:{errors}} = useForm()
  const onSubmit = (data) =>{
    let key = data.code.trim().length === 2 ? 'iso2' : 'iso3'
    setLoading(true)
    setDesc('')
    axios.post('/api/air/airlines/createAirlineByIsoCode', {[key]:data.code })
      .then(()=>setDesc('Успешно!'))
      .catch(sayError).finally(()=>setLoading(false))
    console.log('data', data)
  }
  return (
    <div className="kt-portlet kt-callout kt-callout--diagonal-bg">
      <div className="kt-portlet__body">
        < >
          <form onSubmit={handleSubmit(onSubmit)} className="kt-callout__body">

            <div className="kt-callout__content">
              <h3 className="kt-callout__title">Обновить авиакомпанию по коду</h3>
              <div className="kt-callout__desc mb-2">
                <FormHookInput
                  register={register(`code`, {
                    required: true,
                    maxLength: 3,
                    minLength: 2,
                    onChange: e => {
                      e.target.value = ("" + e.target.value).toUpperCase();
                    }
                  })}
                  placeholder={'iso2 или iso3'}
                  errors={errors}
                />
              </div>
              <p className="kt-callout__desc">
                {desc} <br/>
              </p>
            </div>
            <div className="kt-callout__action d-flex justify-content-end">
              <LoadingButton title={"Старт"} loading={loading}/>
            </div>
          </form>
        </>
      </div>
    </div>
  );
};

export default CreateAirlineByIsoCode;