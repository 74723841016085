export const diagramColors = [
  '#FFCD56FF',
  '#80FF56FF',
  '#FF6384FF',
  '#56ffff',
  '#ad56ff',
  '#e78141',
  '#5686ff',
  '#56ffcc',
  '#4162e7',
  '#e07dfc',
]

export const hoverDiagramColors = [
  '#b28f3c',
  '#5bb73c',
  '#af465c',
  '#3fbbbb',
  '#763bad',
  '#a95f30',
  '#3655a2',
  '#37a282',
  '#253885',
  '#9554a8']
