import {Route, Routes} from "react-router";
import React from "react";
import IncomeList from "./page/list";
import OneCounterparty from "./page/oneCounterparty";
import DetailIncomePage from "./page/detailing";



export default function() {
  return (
    <Routes>
      <Route path={"/"} element={<IncomeList/>} />

      <Route path={"/one"} element={<OneCounterparty/>} />
      <Route path={"/detailing"} element={<DetailIncomePage/>}/>
    </Routes>
  );
}
