import React, {useEffect, useState} from 'react';
import {Portlet, PortletHeader} from "../../../components/Portlet";
import BlockUi from "@availity/block-ui";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import RateHawkDay from "./RateHawkDay";

const RateHawk = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.post("/api/dashboard/unpaidOrdersRatehawk")
      .then(({data})=>{
        console.log(data)
        setData(data.orders)
      }).finally(()=>setLoading(false))
  }, []);

  return (
    <Portlet>
      <PortletHeader title={'Необходимо оплатить RateHawk'}/>
        <BlockUi blocking={loading}>
          {data && Object.keys(data).map((key,index)=>{
            return <RateHawkDay key={index} info={data[key]} label={nameDay[key]}/>
          })}
        </BlockUi>
    </Portlet>
  );
};

export default RateHawk;

const nameDay = {
  overdue: {name:'Просрочено', color:'rgba(253,57,122,0.13 )'},
  today: {name:'Оплата на днях',color:'rgba(255,205,86,0.23)'},
  tomorrow: {name:'Оплата позже', color:'rgba(128,255,86,0.23)'}
}