import ErrorHandler from "../base/components/errorHandler";
import {Route, Routes} from "react-router";
import React from "react";
import TouristServicesList from "./pages/list";
import TouristServicesCreate from "./pages/create";
import TouristServicesEdit from "./pages/edit";


export default function() {
  return (
      <Routes>
        <Route path={'/list'} element={<TouristServicesList/>} />
        <Route path={'/create'} element={<TouristServicesCreate/>} />
        <Route path={"/edit/:id"} exact={true} element={<TouristServicesEdit/>} />

      </Routes>
  );
}