import React, {useEffect, useState} from 'react';
import BlockUi from "@availity/block-ui";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import _ from "lodash"

const CheckTasks = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false)

  const loadInfo = () =>{
    setLoading(true)
    axios.post('/api/dashboard/tuesdayCheck')
      .then(({data})=>{
        setData(data.tasks)

      })
      .finally(()=>setLoading(false))
  }
  useEffect(() => {
    loadInfo()
  }, []);

  const checkTask = (done,id) =>{
    if(window.confirm(done ? 'Отменить выполнение?': 'Вы выполнили задачку?')){
      axios.post('/api/tuesday/switchDone/'+id)
        .then(()=>{
          loadInfo()
        })

    }
  }
  const renderTask = (t) =>{
    return <div className={`d-flex justify-content-between pointer align-items-center ${t.isDone ?'serviceCard':'serviceCardError'}`}
                onClick={()=>checkTask(t.isDone,t.id)}>
      <div >{t.name}{t.isDone &&  <div >Выполнено: {t.doneAt} {t.doneBy}</div>}</div>
      {t.isDone ? <i className="fa fa-check-circle text-success"/> :
        <i className={"fa fa-times-circle text-danger"}/>}
    </div>
  }



  return (
    <BlockUi blocking={loading}>
      {data &&<Portlet>
        <PortletHeader title={'Задачи на сегодня'} toolbar={<PortletHeaderToolbar>
          {data.needDo.length > 0 ?
            <div className={'h5 d-flex gap-2 mb-0'}>Невыполненых : <div className={'text-danger bold'}>{data.needDo.length}</div></div>:
            (data.needDo.length === 0 && data.done.length === 0) ?
              <div className={'h5 mb-0 text-secondary'}>Задач нет</div> :
              <div className={'h5 mb-0 text-success'}>Все сделано!Вы молодец!</div>}
        </PortletHeaderToolbar>}/>
        {data.needDo.map((t, i)=>{
            return <React.Fragment key={i}>
              {renderTask(t)}
            </React.Fragment>
          })}
          {(data.done.length>0 && !show) ? <div className={'link-info pointer'} style={{padding:"13px 25px"}}
                                                onClick={()=>setShow(true)}>Показать выполненные</div>:
            data.done.map((t,i)=>{
              return <React.Fragment key={i}>
                {renderTask(t)}
              </React.Fragment>
            })}
      </Portlet>
      }


    </BlockUi>
  );
};

export default CheckTasks;
