import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import axios from "../../../helpers/axios";
import TouristServicesForm from "../components/TouristServicesForm";
import Loader from "../../../components/loader";
import BlockUi from "@availity/block-ui";
import {RailListRoute, TouristServicesListRoute} from "../../routesConstants";
import moment from "moment/moment";
import _ from 'lodash'
import sayError from "../../../helpers/sayError";

const TouristServicesEdit = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(false)
  const navigate=useNavigate()
  const [data, setData] = useState()

  const getService = () =>{
    setLoading(true)
    axios.get('/api/touristServices/'+id)
      .then(({data})=>{
        console.log('item', data)
        setData({...data.order, edit:true})
        setLoading(false)
      })
  }
  useEffect(() => {
    getService()
  }, [id]);
  const onSubmit = (data) =>{
    console.log('edit')
    setLoading(true)
    data.serviceItems.forEach((item)=>{
      item.startDate = moment(item.startDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
      item.endDate = moment(item.endDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
    })
    const sortingDate = _.sortBy(data.serviceItems, e=>moment(e.endDate).unix()).reverse()
    // data.issueDate = sortingDate[0].endDate
    data.issueDate = moment(data.issueDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')

    axios.patch('/api/touristServices/'+id, data)
      .then(({data})=>{
        navigate(TouristServicesListRoute)
        console.log('uploadstatus',data)
        if(typeof data.uploadResult === 'object'){
          window.alert("Загрузка прошла успешно!Но при выгрузке в 1с произошла ошибка. Обратитесь к тех.специалисту")
        }
      })
      .finally(()=>setLoading(false))
  }
  if(!data){
    return <Loader/>
  }
  const removeOrder = () =>{
    setLoading(true)
    if(window.confirm('Вы уверены что хотите удалить Инвойс?')){
      axios.delete('/api/touristServices/'+id)
        .then(()=>{
          navigate(TouristServicesListRoute)
        })
        .finally(()=>setLoading(false))
    }
  }
  return (
    <>
      <BlockUi blocking={loading}>
        <TouristServicesForm data={data}
                             onSubmit={onSubmit}
                             reload={getService}
                             edit
                             removeOrder={removeOrder}/>
      </BlockUi>
    </>
  );
};

export default TouristServicesEdit;
