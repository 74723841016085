import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Link} from "react-router-dom";
import { TouristServicesCreateRoute, TouristServicesEditRoute } from "../../routesConstants";
import DataTable from "../../../components/dataTable";
import { Tab, Tabs, Col, Row } from "react-bootstrap";
import {useForm} from "react-hook-form";
import ListOptions from "../components/ListOptions";
import numberFormat from '../../../helpers/numberFormat';
import SinkRateHawk from "../../counterparty/components/sinkRateHawk";
import OsvTable from "../components/OsvTable";
import {useNavigate} from "react-router";

const TouristServicesList = () => {
  const navigate = useNavigate()
  const memoryQuery =  JSON.parse(localStorage.getItem("tSQuery")) || {}
  const memoryPage = memoryQuery ? +memoryQuery.page : 0
  const [query, setQuery] = useState(memoryQuery);

  useEffect(() => {
    if(localStorage.getItem("tSQuery")){
      console.log("11111",JSON.parse(localStorage.getItem("tSQuery")))
      localStorage.removeItem("tSQuery")
    }
  }, []);
  const [all, setAll] = useState(false)
  const [tabIndex, setTabIndex] = useState(window.location.hash?.slice(1) ||"home");
  const columns = [
    {
      Header: 'Туристы',
      accessor: 'paxes',
      Cell: ({value,row})=>(
        <div className={'link-info'} style={{cursor:'pointer'}}
          onClick={()=>{
            const memoryPage = localStorage.getItem("page");
            navigate(TouristServicesEditRoute + row.original.id)
            localStorage.setItem("tSQuery",  JSON.stringify({...query, page:localStorage.getItem("page")}))
            console.log(localStorage.getItem("tSQuery"))
        }}>
          {value.map((t, i) => (<React.Fragment key={i}>{t}<br/></React.Fragment>))}
        </div>
      )
    },

    {
      Header: 'Сумма (KZT)',
      accessor: 'summ',
      Cell: ({value,row})=>(<>{numberFormat(value)}</>)
    },
    // {
    //   Header: 'Услуги',
    //   accessor: 'services',
    //   Cell: ({value,row})=>(<>{value.map((t, i) => (<div style={{maxWidth:'150px'}} key={i}>{t}<br/></div>))}</>)
    // },
    // {
    //   Header: 'Инфо',
    //   accessor: 'info',
    //   Cell: ({value,row})=>(<>{value.map((t, i) => (<div style={{maxWidth:'200px'}} key={i}>{t}<br/></div>))}</>)
    // },

    {
      Header: 'Описание',
      accessor: 'description',
    },
    {
      Header: 'Покупатель',
      accessor: 'buyer',
    },
    {
      Header: 'Дата выписки',
      accessor: 'issueDate',
      Cell: ({value,row})=>(<>{value}</>)
    },
    {
      Header: "Оплачен",
      accessor: "paid",
      Cell: ({ value, row }) => (
        <div className={'d-flex justify-content-center'}>
          {!row.original.issuedWithoutDocs ? <i className="fa fa-file-text " style={{color:'rgba(107,112,161,0.8)'}} ></i> : value ? (
            <i className={"fa fa-check-circle text-success"} />
          ) : (
            <i className={"fa fa-times-circle text-danger"} />
          )}
        </div>
      )
    },
  ]
  return (
    <>
      <Tabs
        variant="underline"
        id="controlled-tab-example"
        activeKey={tabIndex}
        onSelect={key => {
          setTabIndex(key)
          window.location.hash = key
        }}
      >
        <Tab eventKey="home" title="Список" >
          <ListOptions setQuery={setQuery} query={query} all={all} setAll={setAll} />
          <Portlet>
            <PortletHeader
              title={"Список туристических услуг"}
              toolbar={
                <PortletHeaderToolbar>
                  <Link to={TouristServicesCreateRoute} className="btn btn-sm btn-primary">
                    <i className="la la-plus"/>
                    Добавить
                  </Link>

                </PortletHeaderToolbar>

              }
            />
            <PortletBody>
              <DataTable columns={columns} search={query} url={`/api/touristServices/`} all={all} startPage={memoryPage}/>
            </PortletBody>
          </Portlet>
        </Tab>
        <Tab eventKey="sink" title="Синхронизировать с RateHawk"  className="tab-no-background">
          <SinkRateHawk active={tabIndex === "sink"}/>
        </Tab>
        <Tab eventKey="osv" title="Сводная таблица"  className="tab-no-background">
          <OsvTable/>
        </Tab>
      </Tabs>


    </>
  );
};

export default TouristServicesList;
