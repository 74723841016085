import { Route, Routes } from "react-router";
import CounterPartyListList from "./pages/list";
import CounterPartyCreatePage from "./pages/create";
import CounterPartyEditPage from "./pages/edit";
import React from "react";
import ErrorHandler from "../base/components/errorHandler";
import CounterpartiesFinderPage from "./pages/counterpartiesFinder";
import ListContacts from "./pages/ contacts/list";

class CounterPartyRouter extends React.Component {
  render() {
    return (
        <Routes>
          <Route path={"/"} exact={true} element={<CounterPartyListList />} />
          <Route path={"create"} element={<CounterPartyCreatePage />} />
          <Route
            path={"edit/:id"}
            exact={true}
            element={<CounterPartyEditPage />}
          />
          <Route path={"counterpartiesFinder"} element={<CounterpartiesFinderPage />} />
          <Route path={"contacts"} element={<ListContacts />} />


        </Routes>
    );
  }
}

export default CounterPartyRouter;
