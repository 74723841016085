import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import LoadingButton from "../../../base/components/loadingButton";
import axios from "../../../../helpers/axios";
import sayError from "../../../../helpers/sayError";
import {noDispHeader} from "../../../../helpers/errorTranslate";

const CreateByDna = ({cb}) => {
  const [loading, setLoading] = useState(false)
  const {register,handleSubmit} = useForm()
  const [info,setInfo]=useState([])
  const onSubmit = (data) =>{
    setLoading(true)
    console.log('data', data)
    axios.post('/api/air/airlines/createAirlineByAmadeusString',data,
      {headers:noDispHeader})
      .then(({data})=>{
        setInfo(['Успешно!',...data.info])
        cb && cb()
      }).catch(sayError)
      .finally(()=>setLoading(false))

  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>Введите DNA(можно несколько)</p>
      <Form.Control
        as="textarea"
        className={'mb-2'}
        placeholder={'DNA DV\n' +
          'DV/VSV 655 JSC AIRCOMPANY SCAT'}
        aria-rowcount={20}
        {...register('input', {required: true, minLength:3})}
      />
      {info.map((m,i)=>(<div key={i}>{m}</div>))}
      <div className={'d-flex justify-content-end'}>
        <LoadingButton loading={loading} title={'Создать'}/>
      </div>
    </form>
  );
};

export default CreateByDna;