import React, {useEffect, useState} from 'react';
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";
import ContactsForm from "./contactsForm";

const EditContacts = ({id, closed}) => {
  const [loading, setLoading] = useState(true)
  console.log('open',id)
  const [data, setData] = useState()
  useEffect(() => {
    axios.get('/api/counterparty/contacts/'+id)
      .then(({data})=>{
        setData({...data.contacts, })
      })
      .finally(()=>setLoading(false))
  }, [id]);
  const onSubmit= (data) =>{
    setLoading(true)
    axios.patch('/api/counterparty/contacts/'+id, data)
      .then(()=>closed())
      .finally(()=>setLoading(false))
  }
  return (
    <BlockUi blocking={loading}>
      {data && <ContactsForm data={data} onSubmit={onSubmit}/>}
    </BlockUi>
  );
};

export default EditContacts;