import React from 'react';
import ApiSelect from "./apiSelect";

const CitySelect = ({cb,value,errors})=>{
  return <ApiSelect
    value={value}
    url={"/api/locations/cities/searchInput"}
    hasError={errors}
    cb={cb}
  />
}

export default CitySelect