import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import sayError from "../../helpers/sayError";
import {useLocation} from "react-router-dom";
import axios from "../../helpers/axios";

const ShortLinkInput = () => {
  const [text, setText] = useState()
  const [shortLink, setShortLink] = useState()
  const [notification,setNotification] = useState()
  const location = useLocation()
  const onPasteAction = (e) =>{
    setNotification(null)
    setText(e.clipboardData.getData("Text"))
    axios.post("/api/shortener/",{url:e.clipboardData.getData("Text")})
      .then(({data})=>{
        console.log("shortUrl",data)
        setShortLink(data.shortUrl)
        try {
          window.navigator.clipboard.writeText(`${data.shortUrl}`)
            .then(() => {
              setNotification('Короткая ссылка скопированна в буфер обмена')
              setTimeout(() => {
                setNotification(null)
              }, 5000)
            })
        } catch (err){
          setNotification('Не удалось скопировать в буфер')
        }
      })
  }
  const clearValues = () =>{
    setText('')
    setShortLink(null)
    setNotification(null)
  }
  useEffect(() => {
    clearValues()
  }, [location.pathname]);
  return (
    <div style={{padding: 15}} className={'d-flex align-items-center gap-2'}>
      <i className="fa fa-refresh text-secondary"
         onClick={clearValues}
         style={{cursor:"pointer", fontSize:16 }}  />
      <div style={{width:'400px'}}>
        <Form.Control
          onPaste={onPasteAction}
          value={text}
          placeholder={'Вставьте ссылку для сокращения'}
          onChange={e=>setText(e.target.value)}
        />
      </div>

      {shortLink && shortLink}
      {notification && <div className={
        notification === 'Не удалось скопировать в буфер' ? 'text-danger': 'text-success'
      }>{notification}</div>}
    </div>
);
};

export default ShortLinkInput;