import React, {useEffect, useState} from 'react';
import {Portlet, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import ParseInput from "../../air/airBooking/components/parseInput";
import {useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import LoadingButton from "../../base/components/loadingButton";
import {Col, Row} from "react-bootstrap";
import RailWaisForm from "../components/RailWaisForm";
import axios from "../../../helpers/axios";
import {useNavigate} from "react-router";
import sayError from "../../../helpers/sayError";

const raiwaysTempData = {
  orderNumber:'',
  issueDate:'',
  issuedBy:'',
  railwaysLogin:'',
  seller:'',
  supplier:'',
  serviceComment:'',
  bayer:'',
  tickets:[
    {
      ticketNumber:'',
      lastName:'',
      firstName:'',
      middleName:'',
      documentNumber:'',
      uin:'',
      departure:'',
      arrivalDate:'',
      serviceClass:'',
      trainNumber:'',
      carriageNumber:'',
      place:'',
      ticketPrice:0,
      fee:0,
      agencyFee:0,
      totalPrice:0,
      status:'',
      refund: false
    }
  ]
}
const RailwaisCreate = () => {
  const [data, setData] = useState(raiwaysTempData)
  const navigate = useNavigate()
  const [errInfo, setErrorInfo] = useState([])
  const formController = useForm()
  const [loading, setLoading] = useState(false)
  const [needCheck, setNeedCheck] = useState()
  const {register,
    handleSubmit,
    getValues} = formController
  const onSubmit = (data) =>{
    setLoading(true)
    const sendData = new FormData();
    sendData.append('file', data.file[0])
    axios.post('/api/railways/parseXlsx', sendData)
      .then((data)=>{
        console.log('datafile', data)
        setData(data.data.orders)
        setErrorInfo(data.data.info)
        setLoading(false)
      })
      .finally(()=>setLoading(false))
    console.log('data', data)
  }
  const mainFormSubmitting = (data, cb ) =>{
    console.log('itog', data)
    axios.put('/api/railways/', data)
      .then(({data})=>{
        if(data.info.length>0){
          setNeedCheck(data.info)
          setErrorInfo([])
          window.alert('С некоторыми бронями возникла ошибка')
        }else{
          navigate('/railways/list')
        }
      })
      .finally(()=>cb())
  }
  return (
    <>
      <Portlet>
        <PortletHeader
          title={"Загрузить файл ЖД билетов"}
          toolbar={
            <PortletHeaderToolbar>
              <form onSubmit={handleSubmit(onSubmit)} className={'d-flex gap-2'}>
                <Form.Control
                  type={"file"}
                  {...register('file', { required: true })}
                />
                <LoadingButton type={'submit'} loading={loading} title={'Загрузить'}/>
              </form>
            </PortletHeaderToolbar>
          }
        />
      </Portlet>
      {errInfo.map((m,i)=>(<p className={'text-danger'}>{m}</p>))}
      <RailWaisForm orders={data}
                    needCheck={needCheck}
                    onSubmit={mainFormSubmitting}/>
    </>

  );
};

export default RailwaisCreate;