import React, {useState} from 'react';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {Portlet, PortletBody, PortletFooter, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import Select from "react-select";
import {Button, Col, Form} from "react-bootstrap";
import {Row} from "reactstrap";
import {useForm} from "react-hook-form";
import clsx from "clsx";
import axios from "../../../helpers/axios";
import AruanaKayarRender from "../components/AruanaKayarRender";
import AruanaIataRender from "../components/AruanaIataRender";
import SilkKayarRender from "../components/SilkKayarRender";
import CustomDatePicker from "../../base/components/forms/customDatePicker";
import sayError from "../../../helpers/sayError";
import {capitalizeFirstLetter} from "../../../helpers/capitalizeFirstLetter";
import GoogleKayarRender from "../components/GoogleKayarRender";
import SiteKayarRender from "../components/SiteKayarRender";
import YanaKayarRender from "../components/YanaKayarRender";
import moment from "moment";

const types = {
  'ak': {
    inputs:[
      {label: 'Начало периода', name: 'start'},
      {label:'Конец периода', name: 'end'}],
    url: '/api/racoon/pdfParse/operativeAruanaKauar'
  },
  'ks': {
    inputs:[{label: 'Silk', name: 'silk', firstPeriod: 'silk', secondPeriod: 'kayar'}],
    url: 'api/racoon/pdfParse/operativeSilkKayar'
  },
  'ai': {
    inputs:[{label: 'Iata', name: 'iata', firstPeriod: 'aruana', secondPeriod: 'iata'}],
    url: '/api/racoon/pdfParse/operativeIataAruana'
  },
  'kg': {
    inputs:[{label: 'Google', name: 'googleFile', firstPeriod: 'silk', secondPeriod: 'kayar'}],
    url:'/api/racoon/pdfParse/checkKayarGoogle'
  },
  'sk':{
    inputs:[
      {label: 'Начало периода', name: 'start'},
      {label:'Конец периода', name: 'end'}],
    url: '/api/racoon/pdfParse/checkKayarSite'
  },
  'yk':{
    inputs:[
      {label: 'Начало периода', name: 'start'},
      {label:'Конец периода', name: 'end'}],
    url: '/api/racoon/pdfParse/operativeKayarYana'
  },
}
const FastCheck = () => {
  const [type, setType] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  console.log(type && types[type])
  const formController = useForm();
  const { control,
    register,
    handleSubmit, reset
  } = formController;
  const renderInputs = (type)=>{
    return types[type].inputs.map((t,i)=>{
      if(type === "ak" || type === "sk" || type === "yk"){
        return <Col key={i} sm={2}>
          <CustomDatePicker formController={formController}
                            label={t.label}
                            name={t.name}
                            rules={{required: true}}/>
        </Col>
      }
      return <Col key={i}>
        <Form.Label>{t.label}</Form.Label>
        <Form.Control
          type={"file"}
          accept={'.xlsx'}
          multiple={true}
          {...register(t.name, {required: true})}
        />
      </Col>

    })
  }
  const onSubmit = (data) =>{
    console.log("data", data)
    setLoading(true)
    let dataF;
    if(type === "ak" || type === "sk"  || type === "yk"){
      dataF = data
      if(data.start.slice(-4)!==data.end.slice(-4)){
        setError(true)
        setLoading(false)
        return
      }
    }else if(type !== "kg"){
      dataF = new FormData();
      Object.keys(data).map((key,i)=>{
        let x = 0
        while (x < data[key].length) {
          dataF.append(`${key}${x}`, data[key][x]);
          x++;
        }
      })
    } else{
      dataF = new FormData();
      console.log('google',data)
      dataF.append(`googleFile`, data.googleFile[0]);
    }

    let pass = types[type].url
    axios
      .post(pass, dataF).then(({data})=>{
      console.log(data)
      setData(data)
    })
      .catch(sayError)
      .finally(()=>{setLoading(false)})
  }
  return (
    <>
      <Paper elevation={0} style={{ marginBottom: 15 }}>
        <Breadcrumbs aria-label="Breadcrumb" style={{padding: 15}}>
          <Typography color="textPrimary">Быстрые сверки </Typography>
          <a
            href="https://convertio.co/ru/xls-xlsx/"
            target={"_blank"}
            referrerPolicy={"no-referrer"}
          >
            Конвертер xls в xlsx
          </a>
        </Breadcrumbs>
      </Paper>
      <Portlet>
        <PortletHeader title={"Все файлы из 1C должны выгруждаться только в формате XSLX"} toolbar={
          <PortletHeaderToolbar>
            <div style={{width: 250}}>
              <Select
                options={[
                  {value: 'ak',label:'Aruana - Kayar'},
                  {value: 'ks',label:'Kayar - Silk'},
                  {value: 'ai',label:'Aruana - Iata'},
                  {value: 'kg',label:'Kayar - Google'},
                  {value: 'sk',label:'Site - Kayar'},
                  {value: 'yk',label:'Kayar - Yana'},
                ]}
                onChange={(val)=> {
                  console.log('select',val)
                  setType(val.value)
                  reset()
                  setData(null)
                }}
              />
            </div>

          </PortletHeaderToolbar>
        } />
        <>
          <PortletBody>

          {type ?
            <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className={'align-items-end'}>
                      {renderInputs(type)}
                      <Col>
                        <Button
                          onClick={handleSubmit(onSubmit)}
                          className={` ${clsx({
                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                          })}`}
                        >
                          Сравнить
                        </Button>
                      </Col>
                    </Row>
                    {error && <p className={'text-danger'}>Период должен быть в пределах 1 года</p>}
                  </form>
            </> :
            <PortletBody>
              Пожалуйста, выберите тип сверки
            </PortletBody>}
          {data && <div className={'mt-4'}>
            <h3>Результаты сверки</h3>
            {/*{type === 'ak' ? <div>
              Kayar - {data.kayarPeriod}<br/>
              Aruana - {data.aruanaPeriod}
            </div> :( type !== 'kg' &&  type !== 'sk' )&& <div>
              {capitalizeFirstLetter(types[type][0].firstPeriod)} - {data[`${types[type][0].firstPeriod}Period`]} <br/>
                {capitalizeFirstLetter(types[type][0].secondPeriod)} - {data[`${types[type][0].secondPeriod}Period`]}
              </div>}
            {type === 'sk' && <div>
              Kayar - {data.kayarPeriod}<br/>
              Сайт - {data.sitePeriod}
            </div>}*/}
            <br/>
            {type === 'ak' ? <AruanaKayarRender data={data}/> :
              type === 'ai' ? <AruanaIataRender data={data}/> :
                type === 'ks' ?<SilkKayarRender data={data}/>:
                  type === 'sk' ?<SiteKayarRender data={data}/>
                    : type === "yk"? <YanaKayarRender data={data}/>
                      :<GoogleKayarRender data={data}/> }
            </div> }
          </PortletBody>
        </>

      </Portlet>
    </>
  );
};

export default FastCheck;



