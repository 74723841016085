import React, {useEffect, useState} from 'react';
import {Portlet, PortletHeader} from "../../../components/Portlet";
import BlockUi from "@availity/block-ui";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import {Link} from "react-router-dom";
import {TouristServicesEditRoute} from "../../routesConstants";

const HotelReservation = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const loadInfo = () =>{
    axios.post("/api/dashboard/hotelReservations")
      .then(({data})=>{
        console.log(data)
        setData(data.hotelReservations)
      }).finally(()=>setLoading(false))
  }
  useEffect(() => {
    loadInfo()
  }, []);
  const toggleCheck = (id) =>{
    if(window.confirm('Вы подтверждаете звонок?')){
      axios.post('/api/dashboard/hotelReservations/check/'+id)
        .then(()=>loadInfo())
    }
  }
  return (
    <Portlet>
      <PortletHeader title={'Необходимо подтвердить бронь RateHawk'}/>
      <BlockUi blocking={loading}>
        {data && data.map((hotel,i)=>{
          const cN = hotel.checked ? 'fa fa-check-circle text-success': 'fa fa-times-circle text-danger'
          const color = hotel.checked ?'rgba(128,255,86,0.23)':'transparent'
          const name =`${hotel.hotel} — ${hotel.checkIn.slice(0, 5)}-${hotel.checkOut.slice(0, 5)} (${hotel.nights} ночи) ${hotel.idInOneC && `№${hotel.idInOneC}`}`
          return <div key={i} style={{
            padding: '1rem 25px',
            backgroundColor: color
          }}>
            <div  className={'d-flex justify-content-between align-items-center'}>
              <div>
                {hotel.mongoId ?
                  <Link
                    to={TouristServicesEditRoute + hotel.mongoId} target={'_blank'}>
                    {name}
                  </Link>:
                  name
                }
                <br/>
                {hotel.mainPax}<br/>
                tel: <a href={`tel:${hotel.phone}`}>{hotel.phone}</a><br/>
                e-mail: <a href={`mailto:${hotel.email}`}>{hotel.email}</a>
                {hotel.upsells.length >0 && <>
                  <br/>
                  доп.услуги: {hotel.upsells.join()}
                </>}
              </div>
              <div>
                <i className={cN}
                   style={{cursor: 'pointer'}}
                   onClick={() => toggleCheck(hotel.id)}/>
              </div>
            </div>
            {hotel.checked && <div className={'text-end'}>Звонил: {hotel.checkedBy}<br/>{hotel.checkedAt}</div>}
          </div>
        })}
      </BlockUi>
    </Portlet>
  );
};

export default HotelReservation;