import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import CreateEditModal from "../components/CreateEditModal";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import EditModal from "../../documents/components/editModal";
import DataTable from "../../../components/dataTable";
import moment from "moment/moment";
import _ from "lodash";
import SearchSelect from "../../../components/searchSelect";

const TuesdayList = () => {
  const user = useSelector(state => state.auth.user);
  // console.log('user',user)
  const [usersList,SetUsersList] = useState()
  const [query,setQuery] = useState({
  })
  const [refetch, setRefetch] = useState()
  useEffect(() => {
    axios.post('/api/users/emplSearch')
      .then(({data})=>{
        console.log('list', data)
        const list = []
        data.data.forEach(u=>{
          list.push({label:u.label, value: u.value._id})
        })
        SetUsersList(list)
      })
      .catch(sayError)
  }, []);

  const checkTask = (done,id) =>{
    if(window.confirm(done ? 'Отменить выполнение?': 'Вы выполнили задачку?')){
      axios.post('/api/tuesday/switchDone/'+id)
        .then(()=>{
          setRefetch(Math.random())
        })
        .catch(sayError)
    }
  }

  const columns = [
    {
      Header: 'Задача',
      accessor: 'name',
      Cell: ({value,row})=>(
        <CreateEditModal usersList={usersList} id={row.original.id} reload={()=>setRefetch(Math.random())}>
          <div className={'link-info pointer'}>{value}</div>
        </CreateEditModal>
      )
    },

    {
      Header: 'Ответственные',
      accessor: 'users',
      Cell: ({value,row})=>(<div>
        {value.map((u,i)=>{
          return <div key={i}>{u} <br/></div>
        })}
      </div>)
    },
    {
      Header: 'Дни',
      accessor: 'taskDays',
      Cell: ({value,row})=>(<div className={'text-capitalize'}>
        {value.map((u,i)=>{
          return <span key={i}>{moment(u, 'E').locale('ru').format('ddd')}{i+1!==value.length && ', '}</span>
        })}
      </div>)
    },
    {
      Header: 'Напоминание',
      accessor: 'taskTimes',
      Cell: ({value,row})=>(<div className={'text-capitalize'}>
        {value.join()}
      </div>)
    },
    {
      Header: "Ближайшее",
      accessor: "isDone",
      Cell: ({ value, row }) => (
        <div className={'link-info pointer'} onClick={()=>checkTask(value,row.original.id)}>
          {value ? <div><i className="fa fa-check-circle text-success m-2" /> {row.original.doneAt} {row.original.doneBy} </div>:
            <><i className={"fa fa-times-circle text-danger m-2"}/> Отметить выполненной</>}
        </div>
      ),
    },
  ]


  return (
    <>
      <Portlet>
        <PortletHeader title={'Список задач'} toolbar={<PortletHeaderToolbar>
          {usersList && <SearchSelect
            onChange={(v) => {
              if (v !== '') {
                setQuery({
                  user: v
                })
              } else {
                setQuery({})
              }
            }}
            style={{width: 200,marginRight:5}}
            defaultValue={''}
            label={'Ответственный'}
            array={[{label: 'Все', value: ''}, ...usersList]}/>}
          <CreateEditModal usersList={usersList} reload={()=>setRefetch(Math.random())}>
            <Button size={'sm'}>
              <i className="la la-plus" style={{fontSize: 14}}/>
              Добавить
            </Button>
          </CreateEditModal>
        </PortletHeaderToolbar>}/>
        <PortletBody>
          <DataTable columns={columns} refetch={refetch} search={query} url={`/api/tuesday/list`}/>
        </PortletBody>
      </Portlet>
    </>

  );
};

export default TuesdayList;