import {Route, Routes} from "react-router";
import React from "react";
import IncomeList from "./page/list";
import OneCounterparty from "./page/oneCounterparty";



export default function() {
  return (
    <Routes>
      <Route path={"/"} element={<IncomeList/>} />

      <Route path={"/one"} element={<OneCounterparty/>} />
    </Routes>
  );
}
