import React, {useEffect, useState} from 'react';
import Loader from "../../../components/loader";
import {useParams} from "react-router-dom";
import axios from "../../../helpers/axios";
import SalesOneCounterparty from "../components/SalesOneCounterparty";
import sayError from "../../../helpers/sayError";
import {useNavigate} from "react-router";
import {SalesRegisterListRoute} from "../../routesConstants";

const EditSalesRegister = () => {
  const {id} = useParams();
  const [loading, setLoading]=useState(false)
  const [data, setData] = useState()
  const navigate = useNavigate()
  useEffect(() => {
    axios.get("/api/salesRegister/"+id)
      .then(({data})=>{
        console.log('editbledata',data)
        setData(data.register)
      })
  }, [id]);
  if(!data){
    return <Loader/>
  }
  const saveRegister = (data) =>{
    setLoading(true)
    axios.patch('/api/salesRegister/'+id,data)
      .then(()=>{
        navigate(SalesRegisterListRoute)
      })
      .catch(sayError)
      .finally(()=>setLoading(false))
  }
  return (
    <div>
      <SalesOneCounterparty cb={saveRegister} data={data} edit saving={loading}/>
    </div>
  );
};

export default EditSalesRegister;