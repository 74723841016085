import React, {useEffect, useState} from 'react';
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../../../components/Portlet";
import {Table} from "reactstrap";
import _ from 'lodash'
import numberFormat from "../../../helpers/numberFormat";
import OsvItem from "./OsvItem";
import axios from "../../../helpers/axios";
import sayError from "../../../helpers/sayError";
import BlockUi from "@availity/block-ui";
import moment from "moment/moment";
import ChangeDate from "../../landing/components/changeDate";

const OsvTable = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const style = {textAlign:'center'}
  const [values, setValues] = useState({
    dateFrom: moment().subtract(6, 'months').toDate(),
    dateTo:moment().toDate()})


  const getItems = () =>{
    setLoading(true)
    axios.post("/api/touristServices/unpaidOrders",{
      start:moment(values.dateFrom).format('DD.MM.YYYY'),
      end:moment(values.dateTo).format('DD.MM.YYYY')
    })
      .then(({data})=>{
        setData(data)
      })
      .catch(sayError).finally(()=>setLoading(false))
  }


  useEffect(() => {
    getItems()
  }, [values]);

  return (
    <Portlet>
      <PortletHeader title={'Таблица долгов по контрагентам без документов'} toolbar={<PortletHeaderToolbar>
        <ChangeDate values={values} set={setValues}/>
      </PortletHeaderToolbar>}/>
      <BlockUi blocking={loading} >
        <PortletBody>
        <Table striped bordered hover>
          <thead>
          <tr>
            <th>Контрагент</th>
            <th>Ссылка</th>
            <th style={style}>Дата</th>
            <th style={style}>Долг</th>
            <th style={{...style, width:'100px'}}>Оплатить</th>
          </tr>
          </thead>
          <tbody>
            {data && data.map((counterparty,index)=>{
              return <React.Fragment key={index}>
                  {counterparty.orders.map((order, i) => {
                    return <tr key={i}>
                      {i === 0 && <td rowSpan={counterparty.orders.length}>
                        <b>{counterparty.name}<br/></b>
                        Всего неоплаченно: {counterparty.orders.length}<br/>
                        Сумма долга: {numberFormat(_.sumBy(counterparty.orders, 'total'))} KZT
                      </td>}
                      <OsvItem order={order} cb={getItems}/>
                    </tr>
                  })}
                {/*</tr>*/}
              </React.Fragment>
            })}
            {data && data.length === 0 && 'Долгов по тур услугам нет'}
          </tbody>
        </Table>
      </PortletBody>
      </BlockUi>
    </Portlet>
  );
};

export default OsvTable;